import React, {useEffect, useState} from "react"
import {Actions, Protected, withAuth} from "../../../security/authorisation";
import {SDSlandingPageTitle} from "../../../components/page-title/page-title";
import {UserService} from "../../../service/service";
import useErrorHandler from "../../../hooks/error-handler";
import {FEDERATED_ID} from "../../../constants/identity-categories";
import {useLocation} from "react-router";
import DataTable from "@sportaus-digital/data-table";
import Spinner from "@sportaus-digital/spinner";
import * as Terms from "../../../constants/terms"

const LinkedAccounts = () => {
    let {location} = useLocation();
    console.log(location);
    const [accounts, setAccounts] = useState([]);
    const {errorHandler} = useErrorHandler();

    useEffect(() => {
        UserService.getOtherIdentities([FEDERATED_ID])
            .then(data => {
                return setAccounts(data);
            })
            .catch((e) => errorHandler(e, "retrieving your identities"))
    }, [setAccounts, errorHandler]);


    return <div>
        <div className="row">
            <div className="col-sm-12">
                <SDSlandingPageTitle byLine={`Linked accounts used with ${Terms.PRODUCT_NAME}`}>
                    Linked accounts
                </SDSlandingPageTitle>
            </div>
        </div>
        <div className="row panel-container">
            <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
                <div className="col-xs-12 col-sm-12">
                <DataTable headers={[
                    {title: "Account Provider", key: "provider", width: "12"},
                    {title: "Email", key: "email", width: "25"},
                    {title: "Status", key: "status", width: "10"},
                   
                ]}
                           data={accounts}
                           spinner={<Spinner hideText={true} inline={true}/>}
                           emptyMessage={<><p>It looks like you’re {Terms.PRODUCT_NAME} isn't linked to a public
                               identity like google or microsoft.</p></>}
                           defaultSort="name"
                           pageSize={20}/>
                </div>
            </Protected>
        </div>
    </div>;
};

LinkedAccounts.propTypes = {};

export default withAuth(LinkedAccounts);