import React from "react"
import SDSpanel from "../../../components/panel/panel";
import { withAuth} from "../../../security/authorisation";
import {SDSlandingPageTitle} from "../../../components/page-title/page-title";
import Button from "@sportaus-digital/buttons";
import keycloak from "../../../security/keycloak";
import {replace} from "lodash";

const SecurityDashboard = () => {
    const mfaAction = <Button link={replace(keycloak.createAccountUrl(), "/account?", "/account/totp?")}
                              className="sds-btn--block"
                              as="tertiary">Set up multi-factor authentication</Button>;

    return <div>
        <div className="row">
            <div className="col-sm-12">
                <SDSlandingPageTitle byLine='Manage security'>
                    Security
                </SDSlandingPageTitle>
            </div>
        </div>
        <div className="row panel-container">
            <div className="col-xs-12 col-sm-6">
                <SDSpanel title="Multi-factor authentication" action={mfaAction}>
                    Add multi-factor authentication to futher secure your account.
                </SDSpanel>
            </div>
        </div>
    </div>;
};

SecurityDashboard.propTypes = {};

export default withAuth(SecurityDashboard);