import React from "react"
import SDSpanel from "../../../components/panel/panel";
import {Actions, Protected, withAuth} from "../../../security/authorisation";
import {SDSlandingPageTitle} from "../../../components/page-title/page-title";
import {useRouteMatch} from "react-router";
import Button from "@sportaus-digital/buttons";
import keycloak from "../../../security/keycloak";
import { Link } from "react-router-dom";

const AccountDashboard = () => {
    const {path} = useRouteMatch();
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <SDSlandingPageTitle
              icon={<span className="fas fa-user-circle fa-4x" />}
              byLine="Manage your account details, linked accounts and security settings."
            >
              Account
            </SDSlandingPageTitle>
          </div>
        </div>
        <div className="row panel-container">
          <div className="col-xs-12 col-sm-6">
            <SDSpanel
              title="Account details"
              action={
                <Button
                  link={keycloak.createAccountUrl()}
                  className="sds-btn--block"
                  as="tertiary"
                >
                  View details
                </Button>
              }
            ></SDSpanel>
          </div>
          <Protected action={Actions.DOCUMENT_VERIFICATION_READ}>
            <div className="col-xs-12 col-sm-6">
              <SDSpanel
                title="Identity Verification"
                action={
                  <Link
                    to={`${path}/documents`}
                    className="au-btn sds-button sds-btn--block sds-button--tertiary"
                  >
                    View details
                  </Link>
                }
              ></SDSpanel>
            </div>
          </Protected>
    
          <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
            <div className="col-xs-12 col-sm-6">
              <SDSpanel
                title="Linked accounts"
                action={
                  <Link
                    to={`${path}/linkedaccounts`}
                    className="au-btn sds-button sds-btn--block sds-button--tertiary"
                  >
                    View details
                  </Link>
                }
              ></SDSpanel>
            </div>
          </Protected>
          <div className="col-xs-12 col-sm-6">
            <SDSpanel
              title="Security"
              action={
                <Link
                  to={`${path}/security`}
                  className="au-btn sds-button sds-btn--block sds-button--tertiary"
                >
                  View details
                </Link>
              }
            ></SDSpanel>
          </div>
        </div>
      </div>
    );
};

AccountDashboard.propTypes = {};

export default withAuth(AccountDashboard);