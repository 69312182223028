import React from "react";
import Organisations from "./organisations/organisations";
import { Route, Switch, useRouteMatch } from "react-router";
import SportsAdmin from "./admin/sports/sports";
import PageAlertContextProvider from "../../utils/page-alerts";
import Organisation from "./organisations/organisation/organisation";
import OrganisationEdit from "./organisations/organisation/organisation-edit/organisation-edit";
import { toNumber } from "lodash";
import SDSsidebar, {
  SDSmenuItem,
  SDSsubMenu,
} from "../../components/sidebar/sidebar";
import UserDataSharing from "./data-sharing";
import OrganisationAdd from "./organisations/organisation-add";
import OrganisationInvitation from "./organisations/organisation/persons/organisation-invitation";
import OrganisationRoles from "./organisations/organisation/roles/organisation-roles";
import OrganisationRoleEdit from "./organisations/organisation/roles/organisation-role-edit";
import OrganisationRoleAdd from "./organisations/organisation/roles/organisation-role-add";
import MedicareCard from "./documents/medicareCard";
import DriversLicence from "./documents/driversLicence";
import BirthCertificate from "./documents/birthCertificate";
import {
  Actions,
  Protected,
  ProtectedPage,
  useAuth,
  withAuth,
} from "../../security/authorisation";
import NotFound from "../page-not-found";
import OrganisationAffiliations from "./organisations/organisation/affiliations/org-affiliations";
import ViewAffiliation from "./organisations/organisation/affiliations/view-affiliation";
import AddAffiliation from "./organisations/organisation/affiliations/add-affiliation";
import OrganisationPersonEdit from "./organisations/organisation/persons/organisation-person-edit";
import CreateOrgAndInvite from "./organisations/organisation/affiliations/create-org-and-invite";
import SDSicon from "../../components/icons/icons";
import { UpdateIdentity, UserIdentities } from "./personal-details";
import { HomeDashboard } from "./home";
import {
  AccountDashboard,
  LinkedAccounts,
  SecurityDashboard,
  Documents,
} from "./account";
import { AdminHome } from "./admin";
import AuthAdmin from "./admin/auth/auth-admin";
import RequestOAuthClient from "./organisations/organisation/app-management/request-oauth-client";
import UpdateOAuthClient from "./organisations/organisation/app-management/update-oauth-client";
import RequestApplication from "./organisations/organisation/app-management/request-application";
import UpdateApplication from "./organisations/organisation/app-management/update-application";
import RegisterWebhook from "./organisations/organisation/app-management/register-webhook";
import UpdateWebhook from "./organisations/organisation/app-management/update-webhook";
import GroupsInvitation from "./organisations/organisation/groups/groups-invitation";
import keycloak from "../../security/keycloak";

const UserHome = () => {
  const { path } = useRouteMatch();
  const { isAdmin } = useAuth();
  let orgBaseUrl = isAdmin
    ? `${path}/admin/organisations`
    : `${path}/organisations`;

  return (
    <div className="container-fluid sds-container__self-service">
      <div className="row">
        <Switch>
          <Route exact path={`${path}`}>
            <Content sidebar={true} baseUrl={path}>
              <HomeDashboard />
            </Content>
          </Route>
          <Route exact path={`${path}/account`}>
            <Content sidebar={true} baseUrl={path}>
              <AccountDashboard />
            </Content>
          </Route>
          <Route exact path={`${path}/account/linkedaccounts`}>
            <Content sidebar={true} baseUrl={path}>
              <LinkedAccounts />
            </Content>
          </Route>
          <Route exact path={`${path}/account/documents`}>
            <Content sidebar={true} baseUrl={path} action={Actions.DOCUMENT_VERIFICATION_READ}>
              <Documents />
            </Content>
          </Route>
          <Route exact path={`${path}/account/documents/MD/add`}>
            <Content
              sidebar={true}
              baseUrl={path}
              action={Actions.DOCUMENT_VERIFICATION_SUBMIT}>
              <MedicareCard mode="add" />
            </Content>
          </Route>
          <Route exact path={`${path}/account/documents/DL/add`}>
            <Content
              sidebar={true}
              baseUrl={path}
              action={Actions.DOCUMENT_VERIFICATION_SUBMIT}
            >
              <DriversLicence mode="add" />
            </Content>
          </Route>
          <Route exact path={`${path}/account/documents/BC/add`}>
            <Content
              sidebar={true}
              baseUrl={path}
              action={Actions.DOCUMENT_VERIFICATION_SUBMIT}
            >
              <BirthCertificate mode="add" />
            </Content>
          </Route>
          <Route exact path={`${path}/account/security`}>
            <Content sidebar={true} baseUrl={path}>
              <SecurityDashboard />
            </Content>
          </Route>
          <Route exact path={`${path}/identities`}>
            <Content
              sidebar={false}
              baseUrl={path}
              action={Actions.USER_PROFILE_LIST_IDENTITIES}
            >
              <UserIdentities />
            </Content>
          </Route>
          <Route
            exact
            path={`${path}/:sport/identities`}
            render={(props) => (
              <Content
                sidebar={false}
                baseUrl={path}
                action={Actions.USER_PROFILE_LIST_IDENTITIES}
              >
                <UserIdentities sportFilter={props.match.params.sport} />
              </Content>
            )}
          />
          <Route
            exact
            path={`${path}/identities/:idHash`}
            render={(props) => (
              <Content
                sidebar={false}
                baseUrl={path}
                action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
              >
                <UpdateIdentity
                  idHash={props.match.params.idHash}
                  idObject={getRouteState(props, "identity")}
                />
              </Content>
            )}
          />
          <Route
            exact
            path={`${path}/:sport/identities/:idHash`}
            render={(props) => (
              <Content
                sidebar={false}
                baseUrl={path}
                action={Actions.USER_PROFILE_UPDATE_IDENTITIES}
              >
                <UpdateIdentity
                  idHash={props.match.params.idHash}
                  idObject={getRouteState(props, "identity")}
                />
              </Content>
            )}
          />
          <Route exact path={`${path}/privacy`}>
            <Content
              sidebar={true}
              baseUrl={path}
              action={Actions.USER_PROFILE_READ_CONSENTS}
            >
              <UserDataSharing />
            </Content>
          </Route>
          <Route exact path={`${path}/admin`}>
            <Content sidebar={true} baseUrl={path}>
              <AdminHome />
            </Content>
          </Route>
          <Route exact path={`${orgBaseUrl}`}>
            <Content
              sidebar={true}
              baseUrl={path}
              action={isAdmin ? Actions.ORG_LIST_ALL : Actions.ORG_LIST_OWN}
            >
              <Organisations />
            </Content>
          </Route>
          <Route exact path={`${orgBaseUrl}/add`}>
            <Content
              action={[Actions.ORG_CREATE, Actions.AFFILIATED_ORG_CREATE]}
            >
              <OrganisationAdd />
            </Content>
          </Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId`}
            render={(props) => (
              <Content
                action={Actions.ORG_VIEW_METADATA}
                orgId={toNumber(props.match.params.orgId)}
              >
                <Organisation orgId={toNumber(props.match.params.orgId)} />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/edit`}
            render={(props) => (
              <Content
                action={Actions.ORG_UPDATE}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationEdit orgId={toNumber(props.match.params.orgId)} />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/invite`}
            render={(props) => (
              <Content
                action={Actions.ORG_INVITE_USER}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationInvitation
                  orgId={toNumber(props.match.params.orgId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/connections`}
            render={(props) => (
              <Content
                action={Actions.ORG_AFFILIATIONS_LIST}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationAffiliations
                  orgId={toNumber(props.match.params.orgId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/connections/add`}
            render={(props) => (
              <Content
                action={[
                  Actions.ORG_AFFILIATIONS_REQUEST,
                  Actions.AFFILIATED_ORG_CREATE,
                ]}
                orgId={toNumber(props.match.params.orgId)}
              >
                <AddAffiliation
                  orgId={toNumber(props.match.params.orgId)}
                  toOrg={getRouteState(props, "toOrg")}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/connections/add-and-invite`}
            render={(props) => (
              <Content
                action={Actions.AFFILIATED_ORG_CREATE}
                orgId={toNumber(props.match.params.orgId)}
              >
                <CreateOrgAndInvite
                  parentOrgId={toNumber(props.match.params.orgId)}
                  newOrgName={getRouteState(props, "newOrgName")}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/connections/:affiliationId`}
            render={(props) => (
              <Content
                action={Actions.ORG_AFFILIATIONS_LIST}
                orgId={toNumber(props.match.params.orgId)}
              >
                <ViewAffiliation
                  affiliationId={toNumber(props.match.params.affiliationId)}
                  orgId={toNumber(props.match.params.orgId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/roles`}
            render={(props) => (
              <Content
                action={Actions.ORG_LIST_ALL_ROLES}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationRoles orgId={toNumber(props.match.params.orgId)} />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/roles/add`}
            render={(props) => (
              <Content
                action={Actions.ORG_CREATE_ROLES}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationRoleAdd
                  orgId={toNumber(props.match.params.orgId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/roles/:roleId`}
            render={(props) => (
              <Content
                action={Actions.ORG_UPDATE_ROLES}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationRoleEdit
                  orgId={toNumber(props.match.params.orgId)}
                  roleId={toNumber(props.match.params.roleId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/person/:personId`}
            render={(props) => (
              <Content
                action={Actions.ORG_READ_USER}
                orgId={toNumber(props.match.params.orgId)}
              >
                <OrganisationPersonEdit
                  orgId={toNumber(props.match.params.orgId)}
                  personId={toNumber(props.match.params.personId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/create`}
            render={(props) => (
              <Content
                action={Actions.ORG_APPLICATIONS_CREATE_APP}
                orgId={toNumber(props.match.params.orgId)}
              >
                <RequestApplication
                  orgId={toNumber(props.match.params.orgId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/:appId`}
            render={(props) => (
              <Content
                action={[
                  Actions.ORG_APPLICATIONS_UPDATE_APP,
                  Actions.GROUP_MANAGEMENT_ORG,
                  Actions.GROUP_MANAGEMENT_APP,
                ]}
                orgId={toNumber(props.match.params.orgId)}
              >
                <UpdateApplication
                  orgId={toNumber(props.match.params.orgId)}
                  appId={toNumber(props.match.params.appId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/:appId/groups`}
            render={(props) => (
              <Content
                action={[
                  Actions.GROUP_MANAGEMENT_ORG,
                  Actions.GROUP_MANAGEMENT_APP,
                ]}
                orgId={toNumber(props.match.params.orgId)}
              >
                <GroupsInvitation
                  orgId={toNumber(props.match.params.orgId)}
                  appId={toNumber(props.match.params.appId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/:appId/client/create`}
            render={(props) => (
              <Content
                action={Actions.ORG_APPLICATIONS_CREATE_CLIENT}
                orgId={toNumber(props.match.params.orgId)}
              >
                <RequestOAuthClient
                  orgId={toNumber(props.match.params.orgId)}
                  appId={toNumber(props.match.params.appId)}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/:appId/client/:oauthClientId`}
            render={(props) => (
              <Content
                action={Actions.ORG_APPLICATIONS_UPDATE_CLIENT}
                orgId={toNumber(props.match.params.orgId)}
              >
                <UpdateOAuthClient
                  orgId={toNumber(props.match.params.orgId)}
                  appId={toNumber(props.match.params.appId)}
                  oauthClientId={toNumber(props.match.params.oauthClientId)}
                  oauthClient={getRouteState(props, "oauthClient")}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/:appId/webhook/create`}
            render={(props) => (
              <Content
                action={Actions.ORG_APPLICATIONS_CREATE_WEBHOOK}
                orgId={toNumber(props.match.params.orgId)}
              >
                <RegisterWebhook
                  orgId={toNumber(props.match.params.orgId)}
                  appId={toNumber(props.match.params.appId)}
                  webhooks={getRouteState(props, "webhooks")}
                  events={getRouteState(props, "events")}
                />
              </Content>
            )}
          ></Route>
          <Route
            exact
            path={`${orgBaseUrl}/:orgId/app/:appId/webhook/:identifier`}
            render={(props) => (
              <Content
                action={Actions.ORG_APPLICATIONS_UPDATE_WEBHOOK}
                orgId={toNumber(props.match.params.orgId)}
              >
                <UpdateWebhook
                  orgId={toNumber(props.match.params.orgId)}
                  appId={toNumber(props.match.params.appId)}
                  identifier={props.match.params.identifier}
                  webhook={getRouteState(props, "webhook")}
                  events={getRouteState(props, "events")}
                />
              </Content>
            )}
          ></Route>
          <Route exact path={`${path}/admin/sports`}>
            <Content sidebar={true} baseUrl={path}>
              <SportsAdmin />
            </Content>
          </Route>
          <Route exact path={`${path}/admin/realms`}>
            <Content sidebar={true} baseUrl={path}>
              <AuthAdmin />
            </Content>
          </Route>

          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

const getRouteState = (props, varName) => {
  return props?.location?.state
    ? props.location.state[varName]
    : undefined;
};

const Sidebar = ({ baseUrl }) => {
  const { isAdmin, Actions } = useAuth();
  let orgLink = isAdmin
    ? `${baseUrl}/admin/organisations`
    : `${baseUrl}/organisations`;

  return (
    <SDSsidebar>
      <SDSmenuItem
        link={`${baseUrl}`}
        label="Home"
        icon={<SDSicon noMargin group="fa" name="fa-home" />}
      ></SDSmenuItem>
      <SDSmenuItem
        link={`${baseUrl}/account`}
        label="Account"
        icon={<SDSicon noMargin group="fa" name="fa-user-circle" />}
      >
        <SDSsubMenu>
          <SDSmenuItem
            link={keycloak.createAccountUrl()}
            isExternal={true}
            label="Account details"
            icon={<SDSicon noMargin group="fa" name="angle-right" />}
          />
          <Protected action={Actions.DOCUMENT_VERIFICATION_READ}>
            <SDSmenuItem
              link={`${baseUrl}/account/documents`}
              isExternal={false}
              label="Identity Verification"
              icon={<SDSicon noMargin group="fa" name="angle-right" />}
            />
          </Protected>
          <Protected action={Actions.USER_PROFILE_LIST_IDENTITIES}>
            <SDSmenuItem
              link={`${baseUrl}/account/linkedaccounts`}
              isExternal={false}
              label="Linked accounts"
              icon={<SDSicon noMargin group="fa" name="angle-right" />}
            />
          </Protected>
          <SDSmenuItem
            link={`${baseUrl}/account/security`}
            isExternal={false}
            label="Security"
            icon={<SDSicon noMargin group="fa" name="angle-right" />}
          />
        </SDSsubMenu>
      </SDSmenuItem>

      <Protected action={Actions.USER_PROFILE_READ_CONSENTS}>
        <SDSmenuItem
          link={`${baseUrl}/privacy`}
          label="Privacy"
          icon={<span className="fa fa-eye-slash" />}
        />
      </Protected>

      <Protected
        action={[
          isAdmin ? Actions.ORG_LIST_ALL : Actions.ORG_LIST_OWN,
          Actions.SPORT_MANAGE,
          Actions.API_KEY_ADMIN,
        ]}
      >
        <SDSmenuItem
          link={`${baseUrl}/admin`}
          label="Administration"
          icon={<SDSicon noMargin group="fa" name="cog" />}
        >
          <SDSsubMenu>
            <Protected
              action={isAdmin ? Actions.ORG_LIST_ALL : Actions.ORG_LIST_OWN}
            >
              <SDSmenuItem
                link={orgLink}
                label="Organisations"
                icon={<SDSicon noMargin group="fa" name="sitemap" />}
              ></SDSmenuItem>
            </Protected>

            <Protected action={Actions.API_KEY_ADMIN}>
              <SDSmenuItem
                link={`${baseUrl}/admin/realms`}
                label="Realms"
                icon={<SDSicon noMargin group="fas" name="key" />}
              />
            </Protected>

            <Protected action={Actions.SPORT_MANAGE}>
              <SDSmenuItem
                link={`${baseUrl}/admin/sports`}
                label="Sports"
                icon={<SDSicon noMargin group="fa" name="running" />}
              />
            </Protected>
          </SDSsubMenu>
        </SDSmenuItem>
      </Protected>
    </SDSsidebar>
  );
};

const Content = ({ baseUrl, sidebar, action, orgId, children }) => {
  if (sidebar) {
    return (
      <>
        <Sidebar baseUrl={baseUrl} />
        <div className="container-right">
          <div className="container-content">
            <PageAlertContextProvider>
              <ProtectedPage action={action} orgId={orgId}>
                {children}
              </ProtectedPage>
            </PageAlertContextProvider>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="container-content">
        <PageAlertContextProvider>
          <ProtectedPage action={action} orgId={orgId}>
            {children}
          </ProtectedPage>
        </PageAlertContextProvider>
      </div>
    );
  }
};

export default withAuth(UserHome);
